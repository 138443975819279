import React from 'react';
//import './Video.css';

const Video = ({ video }) => {
  return (
    <div className="video-item">
      <img 
        src={video.previewImageUrl} 
        alt={video.title} 
        className="video-preview" 
        onError={(e) => e.target.src = '/https://i.imgur.com/25WqMzk.png'} // Мне лень объяснять, надо взглянуть на фото
      />
      <div className="video-info">
        <h3 className="video-title">{video.title}</h3>
        <p className="video-description">{video.description}</p>
      </div>
    </div>
  );
};

export default Video;
