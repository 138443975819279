import React, { useState } from 'react';
import { auth, googleProvider, db } from '../firebase'; // Удалил yahooProvider
import { useNavigate } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import './Register.css';  

function Register() {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);

  const generateDefaultUsername = () => {
    return `User${Math.floor(Math.random() * 10000)}`;
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      await setDoc(doc(db, 'users', user.uid), {
        username: generateDefaultUsername(),
        avatarUrl: user.photoURL,
        email: user.email,
      });

      navigate(`/profile`);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const handlePhoneSignIn = () => {
    setErrorMessages([
      'Функция входа через мобильный телефон временно отключена.',
      'Но Ваши данные были отправлены администратору KotekaHUB.',
      'Ждите гостей завтра в 3:33. 😏😏😏'
    ]);
  };

  const handleSkufuslugiSignIn = () => {
    window.location.href = 'https://skufuslugi.vercel.app';
  };

  return (
    <div className="register-container">
      <h1>Регистрация</h1>
      <button onClick={handleGoogleSignIn} className="google-signin-button">
        Войти через Google
      </button>

      <button onClick={handlePhoneSignIn} className="phone-signin-button" disabled>
        Войти через мобильный телефон
      </button>
      <button onClick={handleSkufuslugiSignIn} className="skufuslugi-signin-button">
        Войти через Скуфуслуги
      </button>
      {errorMessages.map((message, index) => (
        <div key={index} className="error-message">{message}</div>
      ))}
    </div>
  );
}

export default Register;
