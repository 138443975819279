// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider,RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7ZDV_vpwoDnltl967bpasyyI_9zcD7hk",
  authDomain: "kotekahub.firebaseapp.com",
  projectId: "kotekahub",
  storageBucket: "kotekahub.appspot.com",
  messagingSenderId: "518767718956",
  appId: "1:518767718956:web:4bf966c2ce60bfe8c05789",
  measurementId: "G-QMJ8GMREQB"
};
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const yahooProvider = new OAuthProvider('yahoo.com');



export { auth, googleProvider, db, yahooProvider,RecaptchaVerifier, signInWithPhoneNumber };