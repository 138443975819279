import React from 'react';
import './AboutPage.css';

function TermsOfService() {
  return (
    <div className="about-container">
      <h1>Пользовательское соглашение</h1>
      <p className='about-title'>
        Добро пожаловать на Kotekahub! Перед использованием нашего сайта, пожалуйста, внимательно прочитайте данное Пользовательское соглашение. Оно регулирует ваши права и обязанности при использовании платформы Kotekahub. Заходя на наш сайт, вы соглашаетесь соблюдать условия данного соглашения. Если вы не согласны с этими условиями, пожалуйста, не используйте наш сайт.
      </p>
      
      <h2>1. Общие положения</h2>
      <p>
        <strong>1.1. Описание сервиса:</strong> Kotekahub предоставляет пользователям возможность просматривать, лайкать, комментировать и делиться видео, а также сохранять их в избранное и получать рекомендации.
      </p>
      <p>
        <strong>1.2. Возрастные ограничения:</strong> Пользоваться нашим сайтом могут лица, достигшие 13 лет. Пользователи младше 13 лет должны получить разрешение от родителей или опекунов.
      </p>
      
      <h2>2. Пользовательские аккаунты</h2>
      <p>
        <strong>2.1. Регистрация:</strong> Для доступа к некоторым функциям сайта вам может потребоваться регистрация аккаунта. При регистрации вы обязуетесь предоставлять точную и полную информацию о себе.
      </p>
      <p>
        <strong>2.2. Безопасность аккаунта:</strong> Вы несете ответственность за сохранность конфиденциальности своих учетных данных и за все действия, происходящие под вашим аккаунтом. В случае несанкционированного использования аккаунта вы обязаны немедленно сообщить об этом администрации сайта.
      </p>
      
      <h2>3. Контент пользователей</h2>
      <p>
        <strong>3.1. Размещение контента:</strong> Размещая контент на Kotekahub, вы гарантируете, что владеете всеми необходимыми правами на этот контент и что он не нарушает права третьих лиц.
      </p>
      <p>
        <strong>3.2. Права Kotekahub:</strong> Размещая контент на нашем сайте, вы предоставляете Kotekahub неисключительное, бесплатное, всемирное право на использование, распространение, воспроизведение, изменение, адаптацию и демонстрацию данного контента в рамках работы сайта.
      </p>
      <p>
        <strong>3.3. Запрещенный контент:</strong> Запрещается размещать контент, который является незаконным, оскорбительным, клеветническим, порнографическим или нарушающим права третьих лиц.
      </p>
      
      <h2>4. Использование сайта</h2>
      <p>
        <strong>4.1. Правила поведения:</strong> Пользователи обязуются не использовать сайт для размещения спама, вредоносного ПО, мошеннических схем или для совершения иных действий, которые могут нанести ущерб Kotekahub или другим пользователям.
      </p>
      <p>
        <strong>4.2. Санкции:</strong> Нарушение данных правил может привести к блокировке вашего аккаунта или другим санкциям на усмотрение администрации сайта.
      </p>
      
      <h2>5. Политика конфиденциальности</h2>
      <p>
        <strong>5.1. Сбор данных:</strong> Мы собираем персональные данные, такие как ваше юзернейм и другую информацию, необходимую для предоставления наших услуг.
      </p>
      <p>
        <strong>5.2. Использование данных:</strong> Ваши данные используются для обеспечения работы сайта, улучшения его функциональности и предоставления вам персонализированных рекомендаций.
      </p>
      <p>
        <strong>5.3. Передача данных:</strong> Мы не передаем ваши персональные данные третьим лицам, за исключением случаев, предусмотренных законом или необходимых для выполнения наших обязательств перед вами.
      </p>
      
      <h2>6. Отказ от гарантий</h2>
      <p>
        <strong>6.1. Безопасность и надежность:</strong> Kotekahub предоставляет свои услуги "как есть" и не гарантирует, что сайт будет работать без сбоев или ошибок.
      </p>
      <p>
        <strong>6.2. Ответственность:</strong> Мы не несем ответственности за любой ущерб, который может быть причинен в результате использования нашего сайта.
      </p>
      
      <h2>7. Изменения в соглашении</h2>
      <p>
        <strong>7.1. Обновления:</strong> Kotekahub оставляет за собой право изменять данное Пользовательское соглашение в любое время. Изменения вступают в силу с момента их публикации на сайте.
      </p>
      <p>
        <strong>7.2. Уведомления:</strong> Мы будем уведомлять пользователей о существенных изменениях в условиях соглашения через электронную почту или размещением уведомления на сайте.
      </p>
      
      <h2>8. Заключительные положения</h2>
      <p>
        <strong>8.1. Контакты:</strong> Если у вас есть вопросы или предложения по поводу данного Пользовательского соглашения, вы можете связаться с нами через контактную форму на сайте.
      </p>
      <p>
        <strong>8.2. Применимое право:</strong> Настоящее соглашение регулируется законодательством страны, в которой зарегистрирован Kotekahub.
      </p>
      <h4>Последнее изменение: 18.05.24.</h4>
      <p>Спасибо за использование Kotekahub! Надеемся, что вам понравится наш сайт и вы найдете множество забавных и милых видео с котами.</p>
      
      <h6>(соглашение писал gpt-4 o)</h6>
    </div>
  );
}

export default TermsOfService;
