import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">О сайте Котека Хаб</h1>
      <p className="about-description">
        Котека Хаб – это ведущий ресурс, посвященный миру котек и всего, что с ними связано. 
        На нашем сайте вы найдете игровые сервера, видеоролики и многое другое о фурри котеках.
      </p>
      <p className="about-description">
        Наша миссия – делиться знаниями и способствовать распространению информации о котеках, 
        чтобы каждый мог узнать больше об этих замечательных существах и их уходе.
      </p>
      <h2 className="contact-title">Контактная информация</h2>
      <p className="about-description">Если у вас есть вопросы или предложения, пожалуйста, свяжитесь с нами:</p>
      <ul className="contact-list">
        <li>Email: <a href="https://t.me/kotekahub">support@kotekahub.komarovsk.solevoy</a></li>
        <li>Адрес: Комарская Федерация, г. Комарово, ул. Котекина, д. 11</li>
        <li>Discord: <a href="https://discord.gg/8AsXNVYJtv">https://discord.gg/8AsXNVYJtv</a></li>
      </ul>
    </div>
  );
};

export default AboutPage;
