import React from 'react';
import { Link } from 'react-router-dom';
import './AllLinksPage.css';

const AllLinksPage = () => {
  return (
    <div className="all-links-container">
      <h1>🔗 Все ссылки</h1>
      <div className="links-grid">
        <div className="link-item">
          <Link to="/">
            🏠 Главная
          </Link>
        </div>
        <div className="link-item">
          <Link to="/about">
            ℹ️ О сайте
          </Link>
        </div>
        <div className="link-item">
          <Link to="https://discord.gg/8AsXNVYJtv">
            💻 Discord
          </Link>
        </div>
        <div className="link-item">
          <Link to="/terms-of-service">
            📜 Условия использования
          </Link>
        </div>
        <div className="link-item">
          <Link to="/register">
            📝 Регистрация
          </Link>
        </div>
        <div className="link-item">
          <Link to="/upload">
            ⬆️ Загрузить видео
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AllLinksPage;
