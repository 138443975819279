import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import './VideoDetail.css';

const VideoDetailPage = () => {
  const { videoId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const videoDoc = await getDoc(doc(db, 'videos', videoId)); // скибиди
        if (videoDoc.exists()) {
          setVideoData(videoDoc.data());
        } else {
          console.error('Video not found:', videoId);
        }
      } catch (error) {
        console.error('Error fetching video:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoDetails();
  }, [videoId]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!videoData) {
    return <div className="error-message">Видео не найдено.</div>;
  }

  return (
    <div className="video-detail-container">
      <div className="video-container">
        <video controls>
          <source src={videoData.videoUrl} type="video/mp4" />
          Ваш браузер не поддерживается. Поддержка - https://discord.gg/7cZtX4xumY
        </video>
      </div>
      <div className="video-info">
        <h2>{videoData.title || 'No title available'}</h2>
        <p>{videoData.description || 'No description available'}</p>
      </div>
    </div>
  );
};

export default VideoDetailPage;
