import React, { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import VideoList from './VideoList';
import VideoDetailPage from './VideoDetail';
import { auth } from './firebase';
import './App.css';
import './VideoList.css';
import './index.css';

const MainContent = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const recommendedVideos = [
    {
      id: 1,
      title: 'ОБЪЯВЛЕНИЕ ВОЙНЫ',
      url: 'https://kotekahub.skippy.tw1.ru/video/1',
      description: 'ЛУЧШЕЕ ВИДЕО НА КОТЕКА ХАБЕ.',
      thumbnailUrl: 'https://i.postimg.cc/HLFKVQd1/441-smiling-face-with-hearts.png'
    },
    {
      id: 2,
      title: 'семечки купите...',
      url: 'https://kotekahub.skippy.tw1.ru/video/2',
      description: 'пон.',
      thumbnailUrl: 'https://i.postimg.cc/wxLz5rsR/141-glowing-star.png'
    },
    {
      id: 3,
      title: 'spike.secret',
      url: 'https://kotekahub.skippy.tw1.ru/video/3',
      description: 'koteka love spike.secret',
      thumbnailUrl: 'https://dictionary.cambridge.org/ru/images/thumb/black_noun_002_03536.jpg?version=6.0.10'
    },
    {
      id: 4,
      title: 'Я и мои друзья',
      url: 'https://kotekahub.skippy.tw1.ru/video/4',
      description: 'няшное видео от няшки',
      thumbnailUrl: 'https://i.imgur.com/Vt3xval.png'
    },
    {
      id: 5,
      title: 'асмр',
      url: 'https://kotekahub.skippy.tw1.ru/video/5',
      description: 'асмр 100% не троллинг ловушка',
      thumbnailUrl: 'https://i.imgur.com/MUyv8QM.png'
    },
    {
      id: 6,
      title: 'kotyara secret😁',
      url: 'https://kotekahub.skippy.tw1.ru/video/6',
      description: 'зацените',
      thumbnailUrl: 'https://media.tenor.com/lStgaNc7YHAAAAAe/komaru-cat.png'
    },
    {
      id: 7,
      title: 'порно с котекой (7+)',
      url: 'https://kotekahub.skippy.tw1.ru/video/7',
      description: 'Все мы это ждали',
      thumbnailUrl: 'https://i.imgur.com/VmtxDXp.jpeg'
    },
    {
      id: 8,
      title: 'топ5комару',
      url: 'https://kotekahub.skippy.tw1.ru/video/8',
      description: ' Котека съел описание',
      thumbnailUrl: 'https://i.imgur.com/X7pkHOD.png'
    },
    {
      id: 9,
      title: 'котека скачет',
      url: 'https://kotekahub.skippy.tw1.ru/video/9',
      description: 'важное видео от рандом деда',
      thumbnailUrl: 'https://i.imgur.com/dvBVmuU.png'
    },
    {
      id: 10,
      title: 'слив няшки котеки',
      url: 'https://kotekahub.skippy.tw1.ru/video/10',
      description: 'слив вебки 18+',
      thumbnailUrl: 'https://i.imgur.com/GvZjdIH.png'
    },
    {
      id: 11,
      title: ' это не я',
      url: 'https://kotekahub.skippy.tw1.ru/video/11',
      description: 'это не я',
      thumbnailUrl: 'https://i.imgur.com/xNznFHF.png'
    },
    {
      id: 12,
      title: '5 кирпичей недели',
      url: 'https://kotekahub.skippy.tw1.ru/video/12',
      description: '5 кирпичей недели',
      thumbnailUrl: 'https://i.imgur.com/dSt9TG5.png'
    },
    {
      id: 13,
      title: 'EgorTigerYT - Захотел',
      url: 'https://kotekahub.skippy.tw1.ru/video/13',
      description: 'Релиз уже на всех площадках!',
      thumbnailUrl: 'https://i.imgur.com/m7eRnQk.png'
    },
    {
      id: 14,
      title: 'EgorTigerYT - ГИМН ТАЙГЕРИИ!',
      url: 'https://kotekahub.skippy.tw1.ru/video/14',
      description: 'Релиз 12 августа на всех площадках!',
      thumbnailUrl: 'https://i.imgur.com/Iis7WSO.jpeg'
    },
    {
      id: 15,
      title: 'EgorTigerYT - ЛИЦЕМЕРНЫЙ СЁВА',
      url: 'https://kotekahub.skippy.tw1.ru/video/15',
      description: 'Релиз 12 августа на всех площадках!',
      thumbnailUrl: 'https://i.imgur.com/70LpYRa.jpeg'
    },
    {
      id: 16,
      title: 'EgorTigerYT - МАРМЕЛАДИК - КЛАССНЫЙ ЧЕЛ',
      url: 'https://kotekahub.skippy.tw1.ru/video/16',
      description: 'Релиз 12 августа на всех площадках!',
      thumbnailUrl: 'https://i.imgur.com/08xN8pZ.jpeg'
    }
  ];

  return (
    <div>
      <br></br>
      <div className="main">
        <h2 className="text-center">Добро пожаловать{user ? `, ${user.displayName}!` : "!"}</h2>
        <p className="text-center">Это главная страница КотекаХаба. Пока-что посмотрите рекомендованные видео!</p>
        <div className="recommendations">
          <h2 className="text-center">Рекомендуемые видео</h2>
          <VideoList videos={recommendedVideos} emptyHeading="Нет рекомендуемых видео" />
        </div>
      </div>
      <Routes>
        <Route path="/video/:videoId" element={<VideoDetailPage />} />
      </Routes>

    </div>
  );
};

export default MainContent;
