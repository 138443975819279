import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const menuRef = useRef(null);
  const navigate = useNavigate();

  return (
    <header className="header" ref={menuRef}>
      <h1 className="logo">KotekaHUB 😉</h1>
      <div className="menu-icon" onClick={() => navigate('/all')}>
        &#9776;          ..
      </div>
      <nav className="nav-menu">
        <ul>
          <li><Link to="/">Главная</Link></li>
          <li><Link to="/about">О сайте</Link></li>
          <li><Link to="/all">Все ссылки</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
