import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { Link } from 'react-router-dom';
import Video from './Video';
import './VideoList.css';
import Spinner from './Spinner';

const VideoList = ({ emptyHeading }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        // Загружаем рекомендации в фоновом режиме
        const videoCollection = collection(db, 'videos');
        const videoSnapshot = await getDocs(videoCollection);

        // Фильтрация видео по флагу isPrivate
        const videoList = videoSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(video => !video.isPrivate); // Исключаем видео, которые доступны только по ссылке

        setVideos(videoList);
      } catch (error) {
        console.error('Error fetching videos:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const count = videos.length;
  let heading = emptyHeading;

  if (count > 0) {
    const noun = count > 1 ? 'Видео' : 'Видео';
    heading = `${count} ${noun}`;
  }

  return (
    <section className="video-list">
      <h2>{heading}</h2>
      <div className="video-grid">
        {videos.map(video => (
          <Link key={video.id} to={`/video/${video.id}`} className="video-card">
            <Video video={video} />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default VideoList;
