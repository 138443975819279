import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import MainContent from './MainContent';
import AboutPage from './AboutPage';
import VideoDetailPage from './VideoDetail';
import Register from './register/Register';
import TermsOfService from './TermsOfService';
import VideoUpload from './VideoUpload';
import Spinner from './Spinner';
import AllLinksPage from './AllLinksPage';
import './App.css';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Определяем мобильное устройство

  useEffect(() => {
    const loadData = async () => {
      // await new kotekahub
      await new Promise(resolve => setTimeout(resolve, 52)); // Задержка 52 мс, привет Санкт-Петербург!
      setLoading(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  // if (isMobile) {
  //   return (
  //     <div className="mobile-notice">
  //       <img src="https://i.imgur.com/25WqMzk.png" alt="КотекаHUB" />
  //       <h1>Сайт не доступен для мобильных устройств</h1>
  //       <p>Пожалуйста, используйте десктопное устройство для просмотра.</p>
  //     </div>
  //   );
  // }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/video/:videoId" element={<VideoDetailPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/register" element={<Register />} />
          <Route path="/upload" element={<VideoUpload />} />
          <Route path="/all" element={<AllLinksPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
